<template>
  <!-- Error page-->
  <div class="misc-wrapper align-items-baseline" style="padding-top: 8rem;">
    <b-link class="brand-logo" :to="{ name: homeName }">
      <b-img src="@/assets/images/wesoft_1.png" style="width: 120px;" />
    </b-link>
    <div class="misc-inner p-1">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('common.page_not_found') }}
        </h2>
        <p class="mb-2">
          {{ $t('common.page_not_found_details') }}
        </p>

        <p>
          <b-button
            variant="primary"
            class="mb-2"
            :to="{ name: homeName }"
          >
            {{ $t('common.back_to_home') }}
          </b-button>
        </p>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
          style="object-fit: contain; object-position: center top;"
          class="w-100"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import WhLogo from '@/assets/images/wesoft_1.png'
import store from '@/store/index'
import common from '@/common'

export default {
  components: {
    WhLogo
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      homeName: common.getMenuFirstRoute()
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
